if (!String.prototype.reverse) {
	String.prototype.reverse = function(){
		return this.split("").reverse().join("");
	};
}
if (!String.prototype.strtr) {
	String.prototype.strtr = function (replaceObj) {
		if (typeof(replaceObj) != "object") return this;

		var resultStr = "";
		var inStrToArr = this.split('');

		for (var i = 0; i < inStrToArr.length; i++) {
			resultStr += replaceObj[inStrToArr[i]] ? replaceObj[inStrToArr[i]] : inStrToArr[i];
		}
		return resultStr;
	};
}
if (!String.prototype.trim) {
	String.prototype.trim = function() {
		return this.replace(/^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g, '');
	};
}
if (!String.prototype.translate) {
	String.prototype.translate = function () {
		var chars_replacements = {
			'а': 'a', 'б': 'b', 'в': 'v', 'г': 'g', 'д': 'd', 'е': 'e', 'ж': 'g', 'з': 'z',
			'и': 'i', 'й': 'y', 'к': 'k', 'л': 'l', 'м': 'm', 'н': 'n', 'о': 'o', 'п': 'p',
			'р': 'r', 'с': 's', 'т': 't', 'у': 'u', 'ф': 'f', 'ы': 'i', 'э': 'e', 'А': 'A',
			'Б': 'B', 'В': 'V', 'Г': 'G', 'Д': 'D', 'Е': 'E', 'Ж': 'G', 'З': 'Z', 'И': 'I',
			'Й': 'Y', 'К': 'K', 'Л': 'L', 'М': 'M', 'Н': 'N', 'О': 'O', 'П': 'P', 'Р': 'R',
			'С': 'S', 'Т': 'T', 'У': 'U', 'Ф': 'F', 'Ы': 'I', 'Э': 'E', 'ё': "yo", 'х': "h",
			'ц': "ts", 'ч': "ch", 'ш': "sh", 'щ': "shch", 'ъ': "", 'ь': "", 'ю': "yu", 'я': "ya",
			'Ё': "YO", 'Х': "H", 'Ц': "TS", 'Ч': "CH", 'Ш': "SH", 'Щ': "SHCH", 'Ъ': "", 'Ь': "",
			'Ю': "YU", 'Я': "YA", ' ': "_", '/': "", '\\': "", '№': "N", '\'': "", '\"': "", '“': "",
			'+': "_plus_"
		};
		return this.strtr(chars_replacements);
	};
}
if (!String.prototype.toCode) {
	String.prototype.toCode = function () {
		var special_replaces = {
			" ": "_",
			"№": "N",
			"-": "_",
			"#": "",
			"+": "_plus_"
		};
		return this.translate().trim().strtr(special_replaces).replace(/[^A-Za-z0-9-_]+/g, "").replace(/[_]+/g, "_").replace(/^[_]*(.*?)[_]*$/, "$1").toLowerCase();
	};
}
if (!Date.prototype.format) {
	Date.prototype.format = function (mask) {
		var months = ["января", "февраля", "марта", "апреля", "мая", "июня", "июля", "августа", "сентября", "октября", "ноября", "декабря"];
		var year = this.getFullYear();
		var month = this.getMonth();
		var day = this.getDate();
		if (typeof(mask) == "undefined") return day + " " + months[month] + " " + year;
		var date = mask;
		date = date.replace(/dd/, day > 9 ? day : '0' + day);
		date = date.replace(/mm/, month + 1 > 9 ? month + 1 : '0' + (month + 1));
		date = date.replace(/yyyy/, year);
		return date;
	};
}
if (!Array.prototype.chunk) {
	Array.prototype.chunk = function (chunk_size) {
		var chunks = [];
		for (var i = 0; i < this.length; i += chunk_size) {
			chunks.push(this.slice(i, i + chunk_size));
		}
		return chunks;
	};
}
if (!Array.prototype.find) {
	Array.prototype.find = function(predicate) {
		if (this == null) {
			throw new TypeError('Array.prototype.find called on null or undefined');
		}
		if (typeof predicate !== 'function') {
			throw new TypeError('predicate must be a function');
		}
		var list = Object(this);
		var length = list.length >>> 0;
		var thisArg = arguments[1];
		var value;

		for (var i = 0; i < length; i++) {
			value = list[i];
			if (predicate.call(thisArg, value, i, list)) {
				return value;
			}
		}
		return undefined;
	};
}