var __ACTIONS_EDIT = function () {
	'use strict';
	var debug = true;
	var dd = function (message) {
		if (debug) console.log(message);
	};
	var $private = {
		selector: {
			actionItem: '[data-action-id]',
			dataId: 'action-id',
			dataEdit: '[data-edit-action-id]',
			dataAdd: '[data-add-code]',
			dataEditId: 'edit-action-id',
			tools: '.admin-edit-tools',
			visible: '.visible',
			edit: '.edit',
			dbrd_edit: '#content-edit',
			dbrd_add: '#content-add'
		},
		template: {
			frame: '<div class="admin-edit-tools">' +
			'<div class="visible fi icon-lightbulb" onclick="_ACTIONS_ACTIONS.changeActionsStatus({{ id }})" title="Скрыть"></div>' +
			'<div class="edit fi icon-pencil" onclick="_ACTIONS_ACTIONS.editActionForm({{ id }})" title="Редактировать"></div>' +
			'</div>'
		},
		data: {
			user_data: {
				id: null
			}
		},
		functions: {
			checkPermissions: function () {
				return new Promise(function (res, rej) {
					switch (typeof _ACTIONS_ACTIONS !== 'undefined') {
						case true:
							res('access accept');
							break;
						case false:
							rej('access denied');
							break;
						default:
							rej('access denied');
					}
				});
			},
			preInit: function () {
				return new Promise(function (res, rej) {
					if ($($private.selector.actionItem).length) {
						ich.addTemplate('edit', $private.template.frame);
						res([$private.functions.drawFrame, $private.functions.drawAdd]);
					}
					else {
						res([$private.functions.drawEdit]);
					}
				});
			},
			drawFrame: function () {
				return new Promise(function (res, rej) {
					$($private.selector.actionItem).each(function () {
						$private.data.user_data.id = $(this).data($private.selector.dataId);
						$(this).prepend(ich.edit($private.data.user_data))
					});
					$($private.selector.visible).on('click', function () {
						$(this).parents($private.selector.actionItem).toggleClass('disabled');
					});
					res('actions appended');
				});
			},
			drawEdit: function () {
				return new Promise(function (res, rej) {
					if (!$($private.selector.dbrd_edit).length){
						rej('no panel item');
					}
					$($private.selector.dataEdit).show();
					$($private.selector.dataEdit).on('click', function () {
						if (_ACTIONS_ACTIONS.c_window instanceof Object && fw_WIN.instances[_ACTIONS_ACTIONS.c_window.id]) {
							rej('already has window');
						}
						else {
							_ACTIONS_ACTIONS.editActionForm($(this).data($private.selector.dataEditId));
							res('actions appended');
						}
					});

				});
			},
			drawAdd: function () {
				return new Promise(function (res, rej) {
					if (!$($private.selector.dbrd_add).length) {
						rej('no panel item');
					}
					$($private.selector.dataAdd).show();
					$($private.selector.dataAdd).on('click', function () {
						if (_ACTIONS_ACTIONS.c_window instanceof Object && fw_WIN.instances[_ACTIONS_ACTIONS.c_window.id] || $(this).data('add-code') !== 'action') {
							rej('already has window');
						}
						else {
							_ACTIONS_ACTIONS.addForm(true);
							res('actions appended');
						}
					});
				});
			}
		},
		helpers: {}
	};
	var $this = {
		init: function () {
			$private.functions.checkPermissions().then(
				function () {
					$private.functions.preInit()
						.then(function (func) {
							$(func).each(function () {
								this().catch(function (reason) { dd(reason) })
							});
						})
						.catch(function (reason) {
							dd(reason)
						})
				}
			)
				.catch(
					(function (reason) {
						dd(reason);
					})
				);

		}
	};
	return $this.init();
}();